<template>
  <module
      ref="module"
      id="statement"
      titleIcon="fa fa-university"
      :title="$t('statementtxt')"
      :use-default-list="false"
    >
    <div slot="toolbar-global">
      <p-button v-on:click="prevPage()" v-if="adminusr">
        <i slot="label" class="nc-icon nc-minimal-left"></i>
        {{ $t('back') }}
      </p-button>
    </div>
    <div slot="global">
      <!-- In progress -->
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <div class="row" v-else>

        <modal :show.sync="confirmModal" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">
            {{ $t('needconfirmation') }}
          </h4>

          <div class="row">
            <div class="col-md-12 text-center">
              {{ $t('areyousurestatement') }}
            </div>
          </div>

          <template slot="footer">
            <div class="py-3 mx-auto" v-if="modalinprogress">
              <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
            </div>

            <div class="left-side" v-if="!modalinprogress">
              <p-button @click.prevent="confirmDelete" link>
                {{ $t('confirm') }}
              </p-button>
            </div>

            <div class="divider" v-if="!modalinprogress"></div>

            <div class="right-side" v-if="!modalinprogress">
              <p-button type="danger" v-on:click="confirmModal = false" link>
                {{ $t('cancel') }}
              </p-button>
            </div>

          </template>
        </modal>

        <div class="col-md-12 mx-auto">

          <div >
            <div>
              <h6 class="text-center">{{ userid }}</h6>
            </div>

            <div class="col-md-12">

              <div class="row">
                <div class="col-sm-2">
                  <label> {{ permonthTxt }} </label>
                  <el-select v-model="statementbillmonth.month" :placeholder="permonthTxt"
                             v-on:change="statementgetBillMonth">
                    <el-option
                      class="select-default"
                      v-for="item in statementbillmonth.months"
                      :key="item.prop" :label="item.label" :value="item.prop">
                    </el-option>
                  </el-select>
                </div>

                <div class="col-sm-2">
                  <label> {{ peryearTxt }} </label>
                  <el-select v-model="statementbillyear.year" :placeholder="peryearTxt"
                             v-on:change="statementgetBillYear">
                    <el-option
                      class="select-default"
                      v-for="item in statementbillyear.years"
                      :key="item" :label="item" :value="item">
                    </el-option>
                  </el-select>
                </div>

                <div class="col-sm-2">
                  <label> {{ sortbyTxt }} </label>
                  <el-select v-model="statementsortby.column" :placeholder="sortbyTxt"
                             v-on:change="statementsortByCol">
                    <el-option class="select-default"
                               v-for="item in statementsortby.columns"
                               :key="item.prop" :label="item.label" :value="item.prop">
                    </el-option>
                  </el-select>
                </div>

                <div class="col-sm-2">
                  <label> {{ perpageTxt }} </label>
                  <el-select
                    v-model="statementpagination.perPage" :placeholder="perpageTxt">
                    <el-option class="select-default"
                               v-for="item in statementpagination.perPageOptions"
                               :key="item" :label="item" :value="item">
                    </el-option>
                  </el-select>
                </div>

                <div class="col-sm-2">
                  <label> &nbsp </label>
                  <div>
                    <vue-excel-xlsx
                      style="top: -10px;position: relative;"
                      class="btn btn-round btn-info"
                      :data="exportdata"
                      :columns="exportcols"
                      :filename="exportfilename"
                      :sheetname="exportsheetname">
                      <i class="fa fa-download"></i> {{ $t('exportexcel') }}
                    </vue-excel-xlsx>
                  </div>
                </div>


                <div class="col-sm-2">
                  <div class="pull-right">
                    <label> {{  searchTxt }} </label>
                    <fg-input class="input-sm"
                              :placeholder="searchTxt"
                              v-model="statementsearchQuery"
                              addon-right-icon="nc-icon nc-zoom-split">
                    </fg-input>
                  </div>
                </div>



                <div class="col-sm-12 mt-2">
                  <el-table
                    class="table-striped"
                    :data="statementqueriedData"
                    :emptyText="emptyrow"
                    border
                    style="width: 100%">

                    <el-table-column :label="productTxt">
                      <template slot-scope="scope">
                        <span v-if="scope.row.product">
                          {{ scope.row.product }}
                        </span>
                      </template>
                    </el-table-column>

                    <el-table-column
                      v-for="column in statementtableColumns"
                      :key="column.label"
                      :min-width="column.minWidth"
                      :prop="column.prop"
                      :label="column.label">
                    </el-table-column>

                    <el-table-column :label="`${$t('managetxt')}`" v-if="adminusr" align="center">
                      <template slot-scope="scope">
                        <p-button type="danger" link v-on:click="deleteStatement(scope.row)">
                          <i class="fa fa-trash-o" style="font-size: 20px"></i>
                        </p-button>
                      </template>
                    </el-table-column>

                  </el-table>
                </div>
                <div class="col-sm-6 pagination-info">
                  <p class="category">{{ $t('showingtxt') }} {{statementfrom + 1}} {{ $t('totxt') }} {{statementto}} {{ $t('oftxt') }} {{statementtotal}} {{ $t('entriestxt') }}</p>
                </div>
                <div class="col-sm-6">
                  <p-pagination
                    class="pull-right"
                    v-model="statementpagination.currentPage"
                    :per-page="statementpagination.perPage"
                    :total="statementpagination.total">
                  </p-pagination>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </module>
  <!--</div>-->
</template>
<script>
import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import {Tabs, TabPane, Card, Modal} from 'src/components/UIComponents'

import VueExcelXlsx from "vue-excel-xlsx";

Vue.use(VueExcelXlsx)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(require('vue-moment'))

const user_data = JSON.parse(localStorage.getItem('user-info'));

export default {
  components: {
    TabPane,
    Tabs,
    Card,
    PPagination,
    Modal,
  },
  data () {
    return {
      confirmModal: false,
      statementRef: null,
      modalinprogress: false,
      emptyrow: this.$t('norecordsfounded'),
      inprogress: true, statements: [],
      userid: null, adminusr: false,
      ueml: null,
      inprogresstxt: this.$t('loadingtxt') + " " + this.$t('statementtxt') + ". " + this.$t('pleasewait'),
      perpageTxt: this.$t('perpage'),
      sortbyTxt: this.$t('sortby'),
      permonthTxt: this.$t('permonth'),
      searchTxt: this.$t('searchtxt'),
      peryearTxt: this.$t('peryear'),
      productTxt: this.$t('product'),
      currentTab: null,
      exportcols: [
        { label: this.$t('product'), field: "product" },
        { label: this.$t('description'), field: "comment" },
        { label: this.$t('date'), field: "date" },
        { label: this.$t('debits'), field: "debit" },
        { label: this.$t('creditstxt'), field: "credit" },
        { label: this.$t('balance'), field: "balance_after" }
      ],
      exportdata: [],
      exportfilename: null,
      exportsheetname: this.$t('statementtxt'),
      // Statements
      statementcolSort: 'date',
      statementbillyear: {
        year: this.$moment().format("YYYY"),
        years: (function () {
          const beginYear = 2018;
          let currentYear = new Date().getFullYear();
          const filterYears = [];
          while (currentYear >= beginYear) {
            filterYears.push(currentYear);
            currentYear--;
          }
          filterYears.push('All');
          return filterYears;
        })(),
      },
      statementbillmonth: {
        month: this.$moment().format("MM"),
        months: [
          { prop: "01", label: this.$t('jan') },
          { prop: "02", label: this.$t('feb') },
          { prop: "03", label: this.$t('mar') },
          { prop: "04", label: this.$t('apr') },
          { prop: "05", label: this.$t('may') },
          { prop: "06", label: this.$t('jun') },
          { prop: "07", label: this.$t('jul') },
          { prop: "08", label: this.$t('aug') },
          { prop: "09", label: this.$t('sep') },
          { prop: "10", label: this.$t('oct') },
          { prop: "11", label: this.$t('nov') },
          { prop: "12", label: this.$t('dec') },
          { prop: "all", label: this.$t('alltxt') }
        ]
      },
      statementsortby: {
        column: 'date',
        columns: [
          {
            prop: 'product',
            label: this.$t('product')
          },
          {
            prop: 'comment',
            label: this.$t('description')
          },
          {
            prop: 'date',
            label: this.$t('date')
          },
          {
            prop: 'debit',
            label: this.$t('debits')
          },
          {
            prop: 'credit',
            label: this.$t('creditstxt')
          },
          {
            prop: 'balance_after',
            label: this.$t('balance')
          }
        ]
      },
      statementpagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      statementsearchQuery: '',
      statementpropsToSearch: ['product', 'comment'],
      statementtableColumns: [
        {
          prop: 'comment',
          label: this.$t('description'),
          minWidth: 100
        },
        {
          prop: 'date',
          label: this.$t('date'),
          minWidth: 100
        },
        {
          prop: 'debit',
          label: this.$t('debits')
        },
        {
          prop: 'credit',
          label: this.$t('creditstxt')
        },
        {
          prop: 'balance_after',
          label: this.$t('balance')
        }
      ]
    }
  },
  computed: {
    // Debits
    statementpagedData () {
      return this.statements.slice(this.statementfrom, this.statementto)
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    statementqueriedData () {
      if (!this.statementsearchQuery) {
        this.statementpagination.total = this.statements.length
        return this.statementpagedData
      }
      let result = this.statements
        .filter((row) => {
          let isIncluded = false
          for (let key of this.statementpropsToSearch) {
            //let rowValue = row[key].toString()
            let searchRegex = new RegExp(this.statementsearchQuery, 'gi');
            //if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            if (row[key].toString().match(searchRegex) !== null) {
              isIncluded = true
            }
          }
          return isIncluded
        })
      this.statementpagination.total = result.length
      return result.slice(this.statementfrom, this.statementto)
    },
    statementto () {
      let highBound = this.statementfrom + this.statementpagination.perPage
      if (this.statementtotal < highBound) {
        highBound = this.statementtotal
      }
      return highBound
    },
    statementfrom () {
      return this.statementpagination.perPage * (this.statementpagination.currentPage - 1)
    },
    statementtotal () {
      this.statementpagination.total = this.statements.length
      return this.statements.length
    }
  },
  methods: {
    loadDebits(resp) {
      if (!resp.success) {
        return;
      }
      let data = resp.data;

      const now = this.$moment().format("YYYY-MM-DD");

      for(var i=0;i < data.length;i++) {
        let bill = data[i];
        bill.bill_start = this.$moment(bill.bill_start).format("YYYY-MM-DD");
        bill.bill_end = this.$moment(bill.bill_end).format("YYYY-MM-DD");
        if (bill.bill_end == now) {
          bill.bill_end = this.$t('today');
        }
        bill.bill_value = bill.bill_value +" USD";
        // Debit
        data[i].debit = data[i].amount;
        data[i].credit = null;
        this.statements.push(data[i]);
      }
      // Sort and Overwrite Table
      this.statements.sort(this.statementsortIt);
      this.exportdata = this.statements.slice(0);
      // Filename and SheetName
      this.exportfilename = this.$t('statementtxt') +"-";
      if (this.statementbillmonth.month == 'all' || this.statementbillyear.year == 'All') {
        this.exportfilename += "All";
      } else {
        this.exportfilename += this.statementbillyear.year + this.statementbillmonth.month;
      }
      //this.currentTab = 'debits';
      this.statementpagination.currentPage = 1;
      // Show Table
      this.inprogress = false;
    },
    loadDeposits(resp) {
      if (!resp.success) {
        return;
      }
      let data = resp.data;
      this.statements = [];
      const now = this.$moment().format("YYYY-MM-DD");

      for (var i=0;i < data.length;i++) {
        let bill = data[i];
        bill.bill_start = this.$moment(bill.bill_start).format("YYYY-MM-DD");
        bill.bill_end = this.$moment(bill.bill_end).format("YYYY-MM-DD");
        if (bill.bill_end == now) {
          bill.bill_end = this.$t('today');
        }
        bill.bill_value = bill.amount +" USD";
        // Description
        data[i].comment = data[i].description;
        // Credit
        data[i].debit = null;
        data[i].credit = data[i].amount;
        data[i]['product'] = data[i].type
        this.statements.push(data[i]);
      }

      const mo = this.$moment().format("MM");
      const yr = this.$moment().format("YYYY");
      // Get Debits
      this.$getWallet('debits', this.statementbillmonth.month, this.statementbillyear.year, this.ueml)
          .then(this.loadDebits, this.failop);

    },
    // Debits
    statementgetBillMonth(mo) {
      this.inprogress = true;
      this.$getWallet("credits", this.statementbillmonth.month, this.statementbillyear.year, this.ueml)
          .then(this.loadDeposits, this.failop);
    },
    statementgetBillYear(yr) {
      this.inprogress = true;
      this.$getWallet("credits", this.statementbillmonth.month, this.statementbillyear.year, this.ueml)
          .then(this.loadDeposits, this.failop);
    },
    statementsortItNum (a, b) {
      return a[this.statementcolSort] - b[this.statementcolSort];
    },
    statementsortIt (a, b) {
      if (a[this.statementcolSort] < b[this.statementcolSort])
        return -1;
      if (a[this.statementcolSort] > b[this.statementcolSort])
        return 1;
      return 0;
    },
    statementsortByCol(col) {
      this.statementcolSort = col;
      if (col == 'credit' || col == 'debit' || col == 'balance') {
        this.statements.sort(this.statementsortItNum);
      }
      else {
        this.statements.sort(this.statementsortIt);
      }
    },
    prevPage() {
      window.history.back();
    },
    failop (error) {
      console.log(error);
    },
    confirmDelete() {
      this.modalinprogress = true;
      let type = '', tranId = ''
      if (this.statementRef.debit) {
        type = 'debit';
        tranId = this.statementRef.tran_id;
      }
      if (this.statementRef.credit) {
        type = 'credit';
        tranId = this.statementRef.tran_id;
      }
      if (tranId == '') {
        this.$toast.error(this.$t('tranidnotfound'))
        return;
      }
      this.$revertStatement(type, tranId)
        .then(this.successHandler,this.errorHandler)
    },
    deleteStatement(statement) {
      this.modalinprogress = false;
      this.statementRef = statement;
      this.confirmModal = true;
    },
    successHandler(response) {
      if (!response.success) {
        this.errorHandler(response)
        return;
      }
      this.$toast.success(this.$t('statementdeleted'))
      this.modalinprogress = false;
      this.confirmModal = false;

      this.$getWallet("credits", this.statementbillmonth.month, this.statementbillyear.year, this.ueml)
        .then(this.loadDeposits, this.failop);
    },
    errorHandler(response) {
      this.modalinprogress = false;
      console.error(response)
    },
  },
  mounted () {
    this.ueml = user_data.email;

    if (this.$route.params.user_id) {
      this.userid = this.$t('walletbilling') +" "+ this.$t('oftxt') +" "+ this.$route.params.user_id;
      this.ueml = this.$route.params.user_id;
      if(user_data.roles.indexOf('admin') >= 0) {
        this.adminusr = true;
      }
    }
    // Current Month and Year
    this.statementbillmonth.month = this.$moment().format("MM");
    this.statementbillyear.year = this.$moment().format("YYYY");
    // Get Deposits
    this.$getWallet('credits', this.statementbillmonth.month, this.statementbillyear.year, this.ueml)
      .then(this.loadDeposits, this.failop);
  },
  created(){
    this.$setTranslatingInProgress()
  },
  beforeDestroy(){
    this.$root.$off("showTranslatingProgress")
  },
}

</script>
<style>
.abit-down {
  top: 60px;
  position: relative;
  z-index: 999;
}
</style>
